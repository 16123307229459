// Header.tsx
import React, { useState } from "react";
import "./header.css";
import { Link, BrowserRouter as Router } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img
          src={
            require("../../static/yellowHead.png")
            //  require("./static/whileHead.png")
          }
        />
      </div>
      <button className="menu-button" onClick={toggleMenu}>
        {isMenuOpen ? "✕" : "☰"}
      </button>
      <nav className={`menu ${isMenuOpen ? "active" : ""}`}>
        <ul>
          <li>
            <Link style={{ textDecoration: "none" }} to="/">
              首页
            </Link>
          </li>
          <li>
            <Link style={{ textDecoration: "none" }} to="/topUp">
              充值
            </Link>
          </li>
          <li>
            <Link style={{ textDecoration: "none" }} to="/about">
              关于我们
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

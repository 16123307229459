import { Input } from 'antd';
import { useState } from 'react';
import './index.less';
import request from 'umi-request';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'antd-mobile';
import { useEffect } from 'react';

const TopUp = (props) => {
    const [time, setTime] = useState(60)
    const [wait, setWait] = useState(true)
    const [phones, setPhones] = useState('')
    const [codes, setCodes] = useState('')
    const [djs, setDjs] = useState(false)
    const [loginType, setLoginType] = useState(true)
    const navigate = useNavigate();

    const setParams = (e) => {
        setPhones(e.target.value)
    }

    const setCode = (e) => {
        setCodes(e.target.value)
    }

    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            setLoginType(false)
        }
    }, [])

    const login = () => {
        let formdata = new FormData();
        formdata.append("phone", phones);
        formdata.append("code", codes);
        request.post("/member/web/nolog/login", {
            data: formdata
        })
            .then(function (res) {
                console.log(res);
                if (res.code === 200) {
                    sessionStorage.setItem('token', res.data.token)
                    setLoginType(false)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: res.desc,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const sendCode = () => {
        let formdata = new FormData();
        formdata.append("phone", phones);
        formdata.append("sendType", 5);
        if (wait) {
            setDjs(true)
            request.post("/sms/nolog/sendCode", {
                data: formdata
            }).then(function (res) {
                console.log(res);
                if (res.code === 200) {
                    setTime(60); // 设置初始倒计时为60秒  
                    Toast.show({
                        icon: 'success',
                        content: '发送成功',
                    })
                    setWait(false)
                    let times = 60
                    const intervalId = setInterval(() => {
                        times--
                        setTime(time => {
                            return time - 1;
                        });
                        if (times <= 0) {
                            clearInterval(intervalId);
                            setWait(true)
                        }
                    }, 1000); // 每秒更新一次倒计时  
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: res.desc,
                    })
                }
            })
                .catch(function (error) {
                    Toast.show({
                        icon: 'fail',
                        content: error.desc,
                    })
                });
        }
    }
    return (
        <>
            {loginType ? <div className='back1'>
                <div className='borderTop'>
                    <div>账号登录</div>
                    <div>
                        <Input placeholder='请输入你的手机号' onChange={setParams} bordered={false} />
                    </div>
                    <div>
                        <Input placeholder='请输入验证码' bordered={false} onChange={setCode} />
                        <span onClick={sendCode}>{wait ? '获取验证码' : `${time}s`}</span>
                    </div>
                    <div onClick={login}>登录</div>
                </div>
            </div> :
                <div>
                    <div className='topUp'>
                        <div>账号登录</div>
                        <div>
                            <Input placeholder='请输入你的手机号' onChange={setParams} bordered={false} />
                        </div>
                        <div>
                            <Input placeholder='请输入验证码' bordered={false} onChange={setCode} />
                            <span onClick={sendCode}>{wait ? '获取验证码' : `${time}s`}</span>
                        </div>
                        <div onClick={login}>登录</div>
                    </div>
                </div>}
        </>
    )
}

export default TopUp
import React, { useRef, useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/mousewheel";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller } from "swiper/modules";
import "./swiper.css";
import "./index.css";
import About from "../About";

const Home = (props) => {
  // Swiper实例的引用
  const swiperRef = useRef(null);

  // 跟踪Swiper是否已经到达最后一个幻灯片
  const [isEnd, setIsEnd] = useState(false);

  // 在Swiper到达最后一个幻灯片时调用
  const handleReachEnd = () => {
    console.log(123);
    // setIsEnd(true);
  };

  // 在Swiper离开最后一个幻灯片时调用
  const handleSlideChange = (swiper) => {
    if (isEnd && swiper.activeIndex < swiper.slides.length - 1) {
      console.log(456);
      //   setIsEnd(false);
    }
  };

  //   // 控制鼠标滚轮事件
  //   const handleWheel = (event) => {
  //     if (isEnd) {
  //       // 当Swiper到达最后一个幻灯片时，禁用Swiper滚动
  //       swiperRef.current.swiper.disable();
  //     } else {
  //       // 启用Swiper滚动
  //       swiperRef.current.swiper.enable();
  //     }
  //   };

  return (
    <div className="container">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        onReachEnd={handleReachEnd}
        onSlideChange={handleSlideChange}
        // onWheel={handleWheel}
        direction={"vertical"}
        style={{ height: "100vh" }}
        modules={[Controller]}
        mousewheel={{
          releaseOnEdges: true,
          eventsTarget: ".container",
          forceToAxis: true
        }}
        ref={swiperRef}
      >
        <SwiperSlide>
          <div className="swiperWapper">
            <img
              className="textImg"
              src={require("../../static/lbt_title1.png")}
            />
            <div className="downloadWapper">
              <div className="iosBtn">
                <img src={require("../../static/apple.png")} />
                <div
                  className="androidBtn"
                  onClick={() => {
                    window.open("http://app.xiacp.com/fRmc");
                  }}
                >
                  <div style={{ fontSize: "12px", color: "rgb(157,163,176)" }}>
                    Download on the
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      fontWeight: "600"
                    }}
                  >
                    APP Store下载
                  </div>
                </div>
              </div>
              <div
                className="androidBtn"
                onClick={() => {
                  window.open("https://static.xiacp.com/download/daxia.apk");
                }}
              >
                <img src={require("../../static/anzhuo.png")} />
                <div className="btnText">
                  <div style={{ fontSize: "12px", color: "rgb(157,163,176)" }}>
                    Download on the
                  </div>
                  <div style={{ fontSize: "12px", color: "#fff" }}>
                    Android下载
                  </div>
                </div>
              </div>
            </div>
            <div className="tip">
              本应用仅限18岁以上成年人使用，未成年人请勿下载使用
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiperContainer swiperWapper2">
            <div className="left">
              <img
                className="peopleImg"
                src={require("../../static/people2.png")}
              />
              <div className="phone">
                <img
                  className="phoneImg"
                  src={require("../../static/phone2.png")}
                />
                <img
                  className="GamePad slowFloating"
                  src={require("../../static/sbin.png")}
                />
                <img
                  className="note floating"
                  src={require("../../static/yinf.png")}
                />
              </div>
            </div>
            <div className="swiperText">
              <img src={require("../../static/titie2.png")} />
              <div className="text">
                <div className="subTitle">风格各异 清新自由</div>
                <div className="desc">寻找属于你的专属俱乐部</div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiperWapper3">
            <div className="iconWapper">
              <div className="icons ">
                <div className="rollingLeft">
                  <img src={require("../../static/one1.png")} />
                  <img src={require("../../static/one2.png")} />
                  <img src={require("../../static/one3.png")} />
                  <img src={require("../../static/one4.png")} />
                  <img src={require("../../static/one5.png")} />
                  <img src={require("../../static/one6.png")} />
                  <img src={require("../../static/one7.png")} />
                  <img src={require("../../static/one8.png")} />
                  <img src={require("../../static/one9.png")} />
                  <img src={require("../../static/one10.png")} />
                  <img src={require("../../static/one11.png")} />
                  <img src={require("../../static/one12.png")} />
                  <img src={require("../../static/one13.png")} />
                  <img src={require("../../static/one14.png")} />
                  <img src={require("../../static/one15.png")} />
                  <img src={require("../../static/one16.png")} />
                  <img src={require("../../static/one17.png")} />
                  <img src={require("../../static/one18.png")} />
                </div>
              </div>
              <div className="icons " style={{ width: "80%" }}>
                <div className="rollingRight">
                  <img src={require("../../static/two1.png")} />
                  <img src={require("../../static/two2.png")} />
                  <img src={require("../../static/two3.png")} />
                  <img src={require("../../static/two4.png")} />
                  <img src={require("../../static/two5.png")} />
                  <img src={require("../../static/two6.png")} />
                  <img src={require("../../static/two7.png")} />
                  <img src={require("../../static/two8.png")} />
                  <img src={require("../../static/two9.png")} />
                  <img src={require("../../static/two10.png")} />
                  <img src={require("../../static/two11.png")} />
                  <img src={require("../../static/two12.png")} />
                  <img src={require("../../static/two13.png")} />
                  <img src={require("../../static/two14.png")} />
                </div>
              </div>
              <div className="icons ">
                <div className="rollingLeft">
                  <img src={require("../../static/three1.png")} />
                  <img src={require("../../static/three2.png")} />
                  <img src={require("../../static/three3.png")} />
                  <img src={require("../../static/three4.png")} />
                  <img src={require("../../static/three5.png")} />
                  <img src={require("../../static/three6.png")} />
                  <img src={require("../../static/three7.png")} />
                  <img src={require("../../static/three8.png")} />
                  <img src={require("../../static/three9.png")} />
                  <img src={require("../../static/three10.png")} />
                  <img src={require("../../static/three11.png")} />
                  <img src={require("../../static/three12.png")} />
                  <img src={require("../../static/three13.png")} />
                  <img src={require("../../static/three14.png")} />
                  <img src={require("../../static/three15.png")} />
                  <img src={require("../../static/three16.png")} />
                </div>
              </div>
            </div>
            <div className="leftPeople">
              <img className="text" src={require("../../static/titie3.png")} />
              <img
                className="people"
                src={require("../../static/people3.png")}
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiperContainer swiperWapper2 swiperWapper4">
            <div className="left">
              <img
                className="peopleImg"
                src={require("../../static/people4.png")}
              />
              <p className="phone">
                <img
                  className="phoneImg"
                  src={require("../../static/phone4.png")}
                />
                <img
                  className="GamePad slowFloating"
                  src={require("../../static/lb.png")}
                />
              </p>
            </div>
            <div className="swiperText">
              <div className="title">
                <img src={require("../../static/titie4.png")} />
                <img src={require("../../static/dlb.png")} />
              </div>
              <div className="text">
                <div className="subTitle">多种风格 趣味玩法</div>
                <div className="desc">免费生成独一无二的头像</div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={
              "swiperContainer swiperWapper2 swiperWapper4 swiperWapper5"
            }
          >
            <div className="left">
              <img
                className="peopleImg"
                src={require("../../static/people5.png")}
              />
              <p className="phone">
                <img
                  className="phoneImg"
                  src={require("../../static/phone5.png")}
                />
                <img
                  className="GamePad slowFloating"
                  src={require("../../static/fdt.png")}
                />
                <img
                  className="note floating"
                  src={require("../../static/qqiu.png")}
                />
              </p>
            </div>
            <div className="swiperText">
              <div className="title">
                <img src={require("../../static/title5.png")} />
                <img src={require("../../static/rqq.png")} />
              </div>
              <div className="text">
                <div className="subTitle">多元内容，兴趣广场</div>
                <div className="desc">每个人的世界都不一样</div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiperContainer swiperWapper2 swiperWapper4 swiperWapper6">
            <div className="left">
              <img
                className="peopleImg"
                src={require("../../static/people6.png")}
              />
              <p className="phone">
                <img
                  className="phoneImg"
                  src={require("../../static/phone6.png")}
                />
                <img
                  className="GamePad slowFloating"
                  src={require("../../static/jt.png")}
                />
              </p>
            </div>
            <div className="swiperText">
              <div className="title">
                <img src={require("../../static/titie6.png")} />
                <img src={require("../../static/yyh.png")} />
              </div>
              <div>
                <div className="subTitle">互动礼物 CP交友</div>
                <div className="desc">找到属于你的玩伴搭子</div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <About />
    </div>
  );
};

export default Home;

import { useEffect, useState } from "react";
import "./app.less";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/NewHome";
import About from "./pages/About";
import TopUp from "./pages/TopUp";
import Header from "./layout/Header/header.jsx";

function App() {
  const [num, setNum] = useState(0);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (window.location.href.split("/")[3] === "about") {
      setNum(2);
    } else if (window.location.href.split("/")[3] === "topUp") {
      setNum(1);
    } else {
      setNum(0);
    }
  }, []);

  return (
    <div className="app">
      <Header />
  
      {/* <div className={current === 0 ? "head_content" : "head_content1"}>
        <img
          src={
            current === 0
              ? require("./static/yellowHead.png")
              : require("./static/whileHead.png")
          }
        />
        <div>
          <div
            className="box-container"
            onClick={() => {
              setNum(0);
            }}
          >
            <Link
              className={num === 0 ? "border-top" : ""}
              style={{ textDecoration: "none" }}
              to="/"
            >
              首页
            </Link>
          </div>
          <div
            className="box-container"
            onClick={() => {
              setNum(1);
            }}
          >
            <Link
              className={num === 1 ? "border-top" : ""}
              style={{ textDecoration: "none" }}
              to="/topUp"
            >
              充值
            </Link>
          </div>
          <div
            className="box-container"
            onClick={() => {
              setNum(2);
            }}
          >
            <Link
              className={num === 2 ? "border-top" : ""}
              style={{ textDecoration: "none" }}
              to="/about"
            >
              关于我们
            </Link>
          </div>
        </div>
      </div> */}
      <Routes>
        <Route
          path="/"
          element={<Home current={current} setCurrent={setCurrent} />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/topUp" element={<TopUp />} />
      </Routes>
    </div>
  );
}

export default App;

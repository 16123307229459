import "./index.less";

const About = (props) => {
  return (
    <div style={{ overflowY: "scroll" }}>
      <div className="about_content">
        <h1
          style={{
            color: "rgb(64,70,128",
            marginTop: "5%",
            fontSize: "40px",
          }}
        >
          关于我们
        </h1>
        <h2 style={{ color: "rgb(151,152,190)", fontSize: "30px" }}>
          About Us
        </h2>
        <p>
          搭虾是个专门为年轻人打造的社交APP。拥有社交和派对房间的产品矩阵，用户通过声音相遇相交相知、话题广场、多人定制派对房、元宇宙礼物、互动伴宠等产品功能，主打AI数字文化互动特色，与以往的快餐式交友不同，我们更注重交友质量，让你找到志同道合的人生搭子。
        </p>
      </div>
      <div className="about_content">
        <h1
          style={{
            color: "rgb(64,70,128",
            marginTop: "5%",
            fontSize: "40px",
          }}
        >
          企业文化
        </h1>
        <h2 style={{ color: "rgb(151,152,190)", fontSize: "30px" }}>
          Corporate Culture
        </h2>
        <p>
          本公司倡导轻松、有趣的经营理念和文化，愿每一位成员工作开心快乐的同时还能有所进步，不断创新超前，像搭子一样的工作氛围和生活氛围。
        </p>
      </div>
      <div className="img_content">
        <img src={require("../../static/img1.png")} />
        <img src={require("../../static/img2.png")} />
        <img src={require("../../static/img3.png")} />
      </div>
      <img src={require("../../static/fg.png")} style={{ marginTop: "5vh" }} />
      <div style={{ color: "#A69AFD", fontSize: "20px" }}>2023.9.28</div>
      <div className="about_content">
        <h1
          style={{
            color: "rgb(64,70,128",
            marginTop: "5%",
            fontSize: "40px",
          }}
        >
          平台动态
        </h1>
        <h2 style={{ color: "rgb(151,152,190)", fontSize: "30px" }}>
          Platform dynamics
        </h2>
        <div style={{ color: "#000", fontSize: "24px" }}>
          最新产品资讯和公司发展进度，欢迎提出宝贵的意见
        </div>
      </div>
      <div className="dtai">
        <div>
          <img src={require("../../static/dt1.png")} />
          <div style={{ fontSize: "30px", color: "#404680" }}>
            西安、北京入驻
          </div>
          <div style={{ fontSize: "24px", color: "#404680" }}>
            双场地协同经营
          </div>
        </div>
        <div>
          <img src={require("../../static/dt2.png")} />
          <div style={{ fontSize: "30px", color: "#404680" }}>建立合作</div>
          <div style={{ fontSize: "24px", color: "#404680" }}>
            合作共赢、传递快乐
          </div>
        </div>
        <div>
          <img src={require("../../static/dt3.png")} />
          <div style={{ fontSize: "30px", color: "#404680" }}>公众号推文</div>
          <div style={{ fontSize: "24px", color: "#404680" }}>
            行业领先、独立经营
          </div>
        </div>
        <div>
          <img src={require("../../static/dt3.png")} />
          <div style={{ fontSize: "30px", color: "#404680" }}>公众号推文</div>
          <div style={{ fontSize: "24px", color: "#404680" }}>
            全平台覆盖、监管合规
          </div>
        </div>
      </div>
      <div className="bot-back">
        <div className="bot-content">
          <div>
            <p style={{ fontWeight: "600", fontSize: "20px" }}>联系我们</p>
            <p style={{ fontSize: "18px" }}>平台邮箱：xiacp@prawnfire.com</p>
            <p style={{ fontSize: "18px" }}>MCN合作：wangfei@prawnfire.com</p>
            <p style={{ fontSize: "18px" }}>客诉邮箱：wpp@prawnfire.com</p>
            <p style={{ fontSize: "18px" }}>不良信息举报中心：12377</p>
          </div>
          <div>
            <p style={{ fontWeight: "600" }}>关注我们</p>
            <img src={require("../../static/backbtn.png")} />
            <img
              src={require("../../static/backbtn.png")}
              style={{ marginLeft: "3vw" }}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "3vh",
            width: "40vw",
            marginLeft: "30vw",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            style={{ width: "12vw" }}
            src={require("../../static/txy.png")}
          />
          <img style={{ width: "12vw" }} src={require("../../static/cz.png")} />
        </div>
        <div
          className="aboutBottom"
          style={{
            marginTop: "3vh",
            fontSize: "14px",
            width: "54vw",
            marginLeft: "23vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <span>Copyright © 2020-2023 西安虾火科技有限公司</span>
          <span>
            ICP备案号{" "}
            <a href="https://beian.miit.gov.cn/">陕ICP备2023008706号-1</a>
          </span>

          <span>
            <img src={require("../../static/tb.png")} />
            &nbsp;陕公网安备 61019602000289号
          </span>
        </div>
      </div>
    </div>
  );
};

export default About;
